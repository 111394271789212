const dataSlider = [
  {
    id: 1,
    title: "Lorem ipsum",
    subTitle: "Lorem",
    src: "https://www.innaturale.com/es/wp-content/uploads/2018/10/cocinar-semillas-propriedades.jpg",
  },
  {
    id: 2,
    title: "Lorem ipsum",
    subTitle: "Lorem",
    src: "https://lasfor.com.ar/wp-content/uploads/2021/03/dietetica-foto.jpg",
  },
  {
    id: 3,
    title: "Lorem ipsum",
    subTitle: "Lorem",
    src: "https://images.ecestaticos.com/CuMpV9KTHGWz-HH0KG1SZoQb9GA=/0x110:2119x1300/1600x900/filters:fill(white):format(jpg)/f.elconfidencial.com%2Foriginal%2Fbef%2Fe7a%2Fc66%2Fbefe7ac66501753b6524e29e7d896e0b.jpg",
  },
  {
    id: 4,
    title: "Lorem ipsum",
    subTitle: "Lorem",
    src: "https://www.washingtonpost.com/resizer/Hpn0mS3Fr4ntqxBr6aCWpVpGBg0=/arc-anglerfish-washpost-prod-washpost/public/IXMHG5EHJEI6XPSKES4J6YLPFQ.jpg",
  },
  {
    id: 5,
    title: "Lorem ipsum",
    subTitle: "Lorem",
    src: "https://cloudfront-us-east-1.images.arcpublishing.com/infobae/WJ2HH3BJQNFUVMPIVKVMVGKN2A.jpg",
  },
];

export default dataSlider;
